<!--<div cdsTheme="g10">-->
<!--<cds-header brand="Lomni AI" name="Dialpad" [route]="['/']">-->
<cds-header brand="ELS" name="App AI" [route]="['/']">
	<cds-header-navigation *ngIf="authenticated">
		<cds-header-item (click)="navigateToCall()" [isCurrentPage]="isCall" >Dial</cds-header-item>
		<cds-header-item (click)="navigateToLogs()" [isCurrentPage]="isLogs">Logs</cds-header-item>
<!--		<cds-header-item (click)="navigateToBuyMin()">Buy Mins</cds-header-item>-->
		<cds-header-item (click)="navigateToFeedback()">Feedback</cds-header-item>
	</cds-header-navigation>
	<cds-header-global *ngIf="authenticated">
		<cds-header-action (selected)="clickAvatar()" title="action">
			<svg cdsIcon="user--avatar" size="20"></svg>
		</cds-header-action>
	</cds-header-global>
</cds-header>
<!--</div>-->

<cds-panel [expanded]="rightSideOpen">
	<div style="margin-top: 8px">
		<cds-switcher-list>
<!--			<cds-switcher-list-item (click)="navigateToBuyMin()">Buy Minutes</cds-switcher-list-item>-->
			<cds-switcher-list-item (click)="onThemeChange()">{{this.theme === 'white' ? 'Dark Mode' : 'Light Mode'}}</cds-switcher-list-item>
<!--			<cds-switcher-list-item (click)="navigateToCustomerPortal()">My Billing</cds-switcher-list-item>-->
<!--			<cds-switcher-list-item (click)="openUsageModal()">My Usage</cds-switcher-list-item>-->
<!--						<cds-switcher-list-item [route]="['bar']">Contact Us</cds-switcher-list-item>-->
			<cds-switcher-list-item (click)="logout()">Log Out</cds-switcher-list-item>
		</cds-switcher-list>
	</div>
</cds-panel>

<div *ngIf="!authenticated" style="display: flex; justify-content: center; align-items: center; height: 77.5vh;">
	<div style="width: 300px">
<!--		<h4 style="margin-bottom: 8px">Sign In</h4>-->
		<cds-text-label style="margin-bottom: -8px">
			Email
		</cds-text-label>
		<input name="email" (keyup)="onKeyUpEmail($event)" [(ngModel)]="email" cdsText style="margin-top: 8px; margin-bottom: 8px">

		<cds-text-label style="margin-bottom: -8px">
			Password
		</cds-text-label>
		<input (keyup)="onKeyUpPassword($event)" [(ngModel)]="password" type="password" cdsText style="margin-top: 8px; margin-bottom: 8px">

<!--		<div style="margin-top: 4px; margin-bottom: 8px">-->
<!--			<a style="font-size: 12px">Forgot password?</a>-->
<!--		</div>-->

		<cds-button-set>
			<button (click)="login()" cdsButton="primary">Log In</button>
		</cds-button-set>

<!--		<div style="margin-top: 16px; margin-bottom: 8px; font-size: 12px">-->
<!--			No account yet? <a>Sign up</a>-->
<!--		</div>-->
	</div>
</div>

<div *ngIf="authenticated && isCall">
	<div style="padding: 32px">
		<div style="margin-bottom: 8px">
			<cds-dropdown
			  [label]="'Voice'"
			  placeholder="Select"
			  (selected)="selected($event)"
			>
			  <cds-dropdown-list [items]="voices"></cds-dropdown-list>
			</cds-dropdown>
		</div>

		<cds-text-label style="margin-bottom: -8px">
			First Sentence
		</cds-text-label>
		<input (keyup)="onKeyUpFirstSentence($event)" [value]="firstSentence" cdsText style="margin-top: 8px; width: calc(100vw - 64px) !important">


		<cds-textarea-label style="padding-top: 8px">
			Conversation Flow
			<textarea (keyup)="onKeyUpConversationFlow($event)" [value]="conversationFlow" style="width: 100%; min-height: 200px; margin-bottom: 8px" cdsTextArea class="textarea-field">
			</textarea>
		</cds-textarea-label>

		<cds-button-set>
			<button (click)="save()" cdsButton="primary">Save</button>
		</cds-button-set>

		<cds-textarea-label style="padding-top: 32px">
			{{this.varNames}}
			<textarea [(ngModel)]="varValues" style="width: 100%; min-height: 200px; margin-bottom: 8px" cdsTextArea class="textarea-field"></textarea>
		</cds-textarea-label>

		<cds-button-set>
			<button cdsButton="primary" (click)="dial()">Dial</button>
		</cds-button-set>
	</div>

	<div *ngIf="showSaveNotification">
		<cds-actionable-notification
			style="position: fixed; bottom: 16px; right: 16px; z-index: 1000;"
			[notificationObj]="{
					type: 'success',
					template: customActionableContent,
					title: 'Success',
					myData: {
						subtitle: 'Settings saved successfully.'
					},
					showClose: true
				}"
			(close)="showSaveNotification = false"
		></cds-actionable-notification>
	</div>

	<div *ngIf="showDialNotification">
		<cds-actionable-notification
			style="position: fixed; bottom: 16px; right: 16px; z-index: 1000;"
			[notificationObj]="{
					type: 'success',
					template: customActionableContent,
					title: 'Success',
					myData: {
						subtitle: 'Phone calls queued successfully.'
					},
					showClose: true
				}"
			(close)="showDialNotification = false"
		></cds-actionable-notification>
	</div>

	<ng-template #customActionableContent let-data>
	  <p cdsActionableTitle>{{data.title}}</p>
	  <p cdsActionableSubtitle>{{data.myData.subtitle}}</p>
	</ng-template>
</div>

<div *ngIf="authenticated && isLogs">

	<cds-loading *ngIf="logSpinner"
	  [isActive]="logSpinner"
	  [size]="'sm'"
	  [overlay]="true"
	></cds-loading>

	<ng-template #overflowMenuItemTemplate let-data="data">
		<button
			aria-haspopup="true"
			class="cds--overflow-menu"
			type="button"
			(click)="openModal(data)"
		>
			<svg cdsIcon="launch" size="16"></svg>
		</button>
	</ng-template>

	<cds-modal
		[size]="'lg'"
		[open]="modalIsOpen"
	>
	  <cds-modal-header
		  (closeSelect)="closeModal()"
	  >
<!--		<p class="cds&#45;&#45;modal-header__label cds&#45;&#45;type-delta">{{call_id}}</p>-->
		<p class="cds--modal-header__heading cds--type-beta">{{call_id}}</p>
	  </cds-modal-header>
	  <div class="cds--modal-content">
		  <div style="display: flex; justify-content: left; margin-left: -16px">
			  <audio [src]="call_recording_url" controlsList='nodownload noplaybackrate' controls></audio>
		  </div>
		  <p>Call Number<br>{{call_to_number.slice(1)}}<br><br></p>
		  <p>Call Started<br>{{call_started}}<br><br></p>
		  <p>Call Ended<br>{{call_ended}}<br><br></p>
		  <p>Call End Reason<br>{{call_end_reason}}<br><br></p>
		  <p>Call Summary<br>{{call_summary}}<br><br></p>

		  <p>Call Transcript<br></p>
		  <p *ngFor="let message of call_messages">
			  {{message['role'] == 'user' ? 'Human' : 'AI'}}: {{message['message']}}
		  </p>
	  </div>
	</cds-modal>

	<cds-modal
		[size]="'lg'"
		[open]="usageModalIsOpen"
	>
	  <cds-modal-header
		  (closeSelect)="closeUsageModal()"
	  >
		<p class="cds--modal-header__heading cds--type-beta">Minutes Remaining</p>
	  </cds-modal-header>
		<div class="cds--modal-content">
			<p>{{remainingMinutes}}</p>
		</div>
		<cds-modal-footer>
			<ng-container>
				<button
					(click)="navigateToBuyMin()"
					cdsButton="primary">
					Buy Minutes
				</button>
			</ng-container>
		</cds-modal-footer>
	</cds-modal>

	<div style="padding: 32px">
		<div style="margin-bottom: 8px">
			<cds-date-picker
				style="width: calc(100vw - 64px); margin-bottom: 8px;"
				[label]="'Select Date'"
				id="initial-value-datepicker"
				[value]="[this.selectedDate]"
				(valueChange)="selectDate($event[0])"
			></cds-date-picker>
		</div>
		<div class="tiles-row">
			<cds-tile>
				Total Call Minutes<br>
				<H1>{{call_minutes}}</H1>
			</cds-tile>
			<cds-tile>
				Number of Calls
				<H1>{{num_calls}}</H1>
			</cds-tile>
			<cds-tile>
				Average Minutes per Call
				<H1>{{time_per_call}}</H1>
			</cds-tile>
<!--			<cds-tile>-->
<!--				Total Spend-->
<!--				<H1>${{total_spent}}</H1>-->
<!--			</cds-tile>-->
		</div>

		<ng-template #customTableItemTemplate let-data="data">
			<a [attr.href]="data.link">{{data.name}} {{data.surname}}</a>
		</ng-template>

		<cds-table
			[model]="logsData"
			[striped]="false"
			[sortable]="false"
			[noBorder]="true"
		>
		</cds-table>

<!--		<cds-pagination-->
<!--			[model]="pagination"-->
<!--			[itemsPerPageOptions]="[]"-->
<!--		>-->
<!--		</cds-pagination>-->

		<cds-button-set style="margin-top: 8px">
			<button (click)="selectDate(this.selectedDate)" cdsButton="primary">Refresh</button>
		</cds-button-set>
	</div>
</div>
