import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DialComponent } from './dial/dial.component';

const routes: Routes = [
	{
		path: '',
		component: DialComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
