import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { AngularFireModule } from '@angular/fire/compat';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';


// carbon-components-angular default imports
import {
    ButtonModule, DatePickerModule, DialogModule,
    DropdownModule,
    IconModule,
    IconService,
    InputModule, LoadingModule, ModalModule, NotificationModule, PaginationModule, TableModule,
    ThemeModule, TilesModule,
    UIShellModule,
} from 'carbon-components-angular';

// Icons
import Notification20 from '@carbon/icons/es/notification/20';
import UserAvatar20 from '@carbon/icons/es/user--avatar/20';
import Launch16 from '@carbon/icons/es/launch/16';

// Components
import { DialComponent } from './dial/dial.component';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [
		AppComponent,
		DialComponent
	],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        UIShellModule,
        IconModule,
        ThemeModule,
        InputModule,
        DropdownModule,
        ButtonModule,
        TilesModule,
        PaginationModule,
        TableModule,
        HttpClientModule,
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        NotificationModule,
        DatePickerModule,
        DialogModule,
        ModalModule,
        LoadingModule
    ],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(protected iconService: IconService) {
		iconService.registerAll([
			Notification20,
			UserAvatar20,
			Launch16
		]);
	}
}
