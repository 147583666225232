// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: "AIzaSyC9ihC8RVsVNJ4FLOM543H7NjVQv2FQKl0",
		  authDomain: "lomni-51700.firebaseapp.com",
		  projectId: "lomni-51700",
		  storageBucket: "lomni-51700.appspot.com",
		  messagingSenderId: "465083617808",
		  appId: "1:465083617808:web:46c9c032ea0c1c784df095",
		  measurementId: "G-Z9YBTPP5TP"
	  }
};
